import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom"
import { getDataBulletinBoard } from '../../services/bulletin-board/action'
import { baseUrl } from '../../common/config';
import BulletinSkeletonLoading from '../../common/skeleton-loading/bulletin-skeleton-loading';
import { BULLETIN_PAGE_META_TYPE, loadMetaTags, SITE_TITLE } from '../../common/common';
import { useSelector } from 'react-redux';

const BulletinBoard = () => {
    const [bulletinBoard, setBulletonBoard] = useState([]);
    const [loading, setLoading] = useState(true);

    const { headerFooter } = useSelector((state) => state.headerFooter);
    const site_title = headerFooter?.info?.CONF_SITE_TITLE || SITE_TITLE;
    const metaTags = {
        meta_type: BULLETIN_PAGE_META_TYPE,
        meta_title: `${site_title} | Bulletin Board | Quarterback Camps & Sessions`,
        meta_record_id: 0,
        meta_advance_tag: 1
    }

    useEffect(() => {
        loadMetaTags(metaTags);
        const delaySearch = setTimeout(() => {
            loadBulletinBoardData();
        }, 1000);
        return () => clearTimeout(delaySearch);
    }, []);

    const loadBulletinBoardData = async () => {
        const response = await getDataBulletinBoard();
        setLoading(false);
        setBulletonBoard(response.events);
    }
    return (
        <>
            <div className="player-list-bulletin-board">
                <div className="container">
                    <div className="row bulletin-boards">
                        <div className="col-lg-12">
                            <div className="player-heading">
                                <h2>Bulletin board</h2>
                            </div>
                        </div>
                        <div className="row bulletin-boards mb-5">
                            {loading ? (
                                <BulletinSkeletonLoading length={9} />
                            ) : (
                                <>
                                    {bulletinBoard && bulletinBoard.length > 0 ? (
                                        bulletinBoard.map((elem, index) => {
                                            if(elem.tads_title && elem.tads_link && elem.images){
                                                return (
                                                    <div key={index} className="col-lg-4 col-sm-4 mt-3">
                                                        <div className="buttetin-image">
                                                            {elem.images && (
                                                                <Link to={elem.tads_link}>
                                                                    <img
                                                                        src={`${baseUrl}/${elem.images[0].afile_physical_path}`}
                                                                        className="img-fluid"
                                                                        alt={elem.tads_title}
                                                                    />
                                                                </Link>
                                                            )}
                                                            <h5 className="mt-2"><Link to={elem.tads_link} target="_blank">{elem.tads_title}</Link></h5>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    ) : (
                                        <div className="col-lg-12 text-center">
                                            <p>No Record Found</p>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-color-coupon">
                <div className="container-fluid custom-width">
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 col-md-12 col-xl-12 col-xxl-12 text-center">
                            <h1>Looking to promote YOUR business to over 5,000 QBs nationwide?</h1>
                            <p><Link to="/signup"><strong>CLICK HERE TO GET STARTED! </strong></Link> Create a Trainer Profile for FREE!</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BulletinBoard
