import React from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { baseUrl } from '../../common/config';
import { formatOfferDate, getUserProfileStyles } from '../../../common/common';
import { getLabel } from '../../../common/getLabel';

const PremiumOffers = ({ sliderSetting, premiumOffers }) => {

    const updatedSliderSetting = {
        ...sliderSetting,
        arrows: premiumOffers && premiumOffers.length > 4 ? true : false
    }

    return (
        <>
            <div className="container-fluid custom-width premium">
                <div className="row justify-content-center">
                    <div className="col-lg-12 qbh-padding-left">
                        <div className="heading">
                            <h2>PREMIUM <span className="account-color">OFFERS / COMMITS</span></h2>
                            <div className="space-line"></div>
                        </div>
                    </div>

                    <Slider className="row popular-product-slider" {...updatedSliderSetting}>
                        {premiumOffers && premiumOffers.map((profile, index) => {
                            let starsRating = '';
                            if (profile["poffers.uprofile_style"] === 3 || profile["poffers.uprofile_style"] === 4) {
                                starsRating = (profile.wr_te_stars) ? (profile.wr_te_stars * 100) / 5 : '';
                            } else {
                                starsRating = (profile.stars) ? (profile.stars * 100) / 5 : '';
                            }
                            return (
                                <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3 col-xxl-3 popular-product-slide" key={index}>
                                    <div className="qbh-box-main">
                                        <div className="qbh-image-box profile--slider">
                                            <figure><Link to={`/profile/${profile["poffersUrl.urlrewrite_custom"]}`}>
                                                <img
                                                    src={profile.images && profile.images[0] && profile.images[0].afile_physical_path ? `${baseUrl}/${profile.images[0].afile_physical_path}` : "/images//qbh-hit.jpg"}
                                                    className="img-fluid"
                                                    alt={profile["poffers.uprofile_fname"]}
                                                />
                                            </Link></figure>
                                        </div>
                                        <div className="pro-style">
                                            <div className="qbh-name-sports">
                                                <div className="name"><span className="pro">{getUserProfileStyles[profile["poffers.uprofile_style"]]}</span></div>
                                            </div>
                                        </div>
                                        <div className="left-space">
                                            {profile.school_image && profile.school_image[0] && (
                                                <div className="logo-icon">
                                                    <figure><img src={`${baseUrl}/${profile.school_image[0].afile_physical_path}`} className="img-fluid" /></figure>
                                                </div>
                                            )}
                                            <div className="sports-name"><Link to={`/profile/${profile["poffersUrl.urlrewrite_custom"]}`}>{profile["poffers.uprofile_fname"]} {profile["poffers.uprofile_lname"]}</Link></div>
                                            <div className="class-sport">Class of {profile["poffers.uprofile_grad_class"]}</div>
                                            {starsRating ? (
                                                <div className="review__rating star-rating-menu">
                                                    <div className="icn" style={{ width: starsRating + '%' }}></div>
                                                </div>
                                            ) : (
                                                <>
                                                    {getLabel('LBL_Not_Yet_Rated')}
                                                </>
                                            )}
                                            <div className="high-school">Offer Date : <span className="date"> {formatOfferDate(profile.poffer_date)}</span></div>
                                            <div className="player-id">{profile.poffer_college}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <div className="view-all-btn"><Link to="/premium-offers-commits" className="btn btn-primary ">{getLabel('LBL_View_All')}</Link></div>
            </div>
        </>
    )
}

export default PremiumOffers
