import React, { useState } from 'react'
import { Link, NavLink, useNavigate } from "react-router-dom"
import { doLogout, isLoggedIn } from '../services/authentication/action'
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from './config';

const Header = (props) => {
    const { headerFooter } = props;
    const loggedIn = useSelector(isLoggedIn);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const user_fname = userDetails ? userDetails.user_fname : 'User';
    const user_type = userDetails ? userDetails.user_type : '';
    const headerMenus = headerFooter ? headerFooter.header.links : [];
    const headerLogo = headerFooter ? headerFooter.header.logo : [];
    const generalConfigurations = headerFooter ? headerFooter.info : [];
    const [searchText, setSearchText] = useState('');
    const navigate = useNavigate();
    const handleSearch = (e) => {
        e.preventDefault();
        if (searchText.trim()) {
            const formattedSearch = searchText.trim().replace(/\s+/g, '+');
            navigate(`/qb-profiles/?keyword=${formattedSearch}`);
        }
    };

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleNavItemClick = () => {
        setIsMenuOpen(false);
    };
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(doLogout());
        handleNavItemClick();
        navigate('/');
    };

    return (
        <>
            <div className="top-bar-qbh-bg-stick" id="myHeader">
                <div className="top-bar-qbh-bg-color">
                    <div className="follow-us-qbh">
                        <ul>
                            {generalConfigurations.CONF_SOCIAL_MEDIA_TWITTER_URL && (
                                <li><Link to={generalConfigurations.CONF_SOCIAL_MEDIA_TWITTER_URL} target="_blank">Follow us on Twitter : <i className='bx bxl-twitter'></i></Link></li>
                            )}
                            {generalConfigurations.CONF_SITE_OWNER_EMAIL && (
                                <li><a href={`mailto:${generalConfigurations.CONF_SITE_OWNER_EMAIL}`}>Email : {generalConfigurations.CONF_SITE_OWNER_EMAIL} <i className='bx bxs-envelope-open'></i> </a></li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className="container">
                    <div className="row custom-section">
                        <div className="col-lg-8 col-sm-12 col-md-12 col-xl-8 col-xxl-8 menu-padding">
                            <div className="custom-logo">
                                <div className="qbh-logo">
                                    <NavLink to="/">
                                        <img
                                            src={headerLogo && headerLogo.length > 0 ? `${baseUrl}/${headerLogo[0].afile_physical_path}` : "/images/logo.png"}
                                            className="img-fluid"
                                            alt="Site Logo"
                                            loading="lazy"
                                            width="269"
                                            height="73"
                                        />
                                    </NavLink>
                                </div>
                                <div className="search">
                                    <form onSubmit={handleSearch}>
                                        <input type="text" className="form-control" onChange={(e) => setSearchText(e.target.value)} placeholder="Search here ...." />
                                        <button type="submit" className="btn btn-primary"><i className='bx bx-search'></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-12 col-md-12 col-xl-4 col-xxl-4 menu-padding-right">
                            <div className="login-signup">
                                <ul>
                                    {!loggedIn ? (
                                        <>
                                            <li><NavLink to="/log-in"><i className='bx bx-download bx-rotate-270'></i> Sign In </NavLink></li>
                                            <li><NavLink to="/signup"><i className='bx bx-user'></i> Free Sign Up</NavLink></li>
                                        </>
                                    ) : (
                                        <>
                                            <li>
                                                <div className="dropdown">
                                                    <Link to="#" className="dropdown-toggle bx bx-user" data-bs-toggle="dropdown"><span className="sign-dropdown">Hello, {user_fname}</span></Link>
                                                    <div className="dropdown-menu">
                                                        {user_type != 4 && (
                                                            <Link to="/qbhl-admin" className="dropdown-item">Your Profiles</Link>
                                                        )}
                                                        <Link to="/qbhl-admin/account" className="dropdown-item">Account Settings</Link>
                                                        <a className="dropdown-item" onClick={handleLogout}>Sign Out</a>
                                                    </div>
                                                </div>
                                            </li>
                                        </>
                                    )}
                                    <li><NavLink to="/trainers"><i className='bx bx-user-circle'></i> Need a Trainer </NavLink></li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="bg-color">
                    <div className="container">
                        <div className="row custom-menu">
                            <nav>
                                <div className="navbar">
                                    <i className='bx bx-menu' onClick={handleMenuToggle}></i>
                                    <div className="nav-links col-md-12" style={isMenuOpen ? { right: "0px" } : {}}>
                                        <div className="sidebar-logo">
                                            <i className='bx bx-x' onClick={handleMenuToggle}></i>
                                            <span className="logo-name">
                                                <NavLink to="/">
                                                    <img
                                                        className="img-fluid"
                                                        src={headerLogo && headerLogo.length > 0 ? `${baseUrl}/${headerLogo[0].afile_physical_path}` : "/images/logo.png"}
                                                        alt="Site Logo"
                                                        loading="lazy"
                                                        width="269"
                                                        height="73"
                                                        onClick={handleNavItemClick}
                                                    />
                                                </NavLink>
                                            </span>
                                        </div>
                                        <ul className="links">
                                            {Object.entries(headerMenus).map(([index, menu]) => (
                                                <li key={index}>
                                                    <NavLink to={menu.url} onClick={handleNavItemClick}>{menu.title}</NavLink>
                                                    {menu.children && menu.children.length > 0 && (
                                                        <>
                                                            <i className='bx bxs-chevron-down htmlcss-arrow arrow'></i>
                                                            <ul className="htmlCss-sub-menu sub-menu">
                                                                {menu.children.map((submenu, subIndex) => (
                                                                    <li key={subIndex}><NavLink to={submenu.url} onClick={handleNavItemClick}>{submenu.title}</NavLink></li>
                                                                ))}
                                                            </ul>
                                                        </>
                                                    )}
                                                </li>
                                            ))}
                                            <div className="login-signup">
                                                <ul>
                                                    {!loggedIn ? (
                                                        <>
                                                            <li><NavLink to="/log-in" onClick={handleNavItemClick}><i className='bx bx-download bx-rotate-270'></i> Sign In </NavLink></li>
                                                            <li><NavLink to="/signup" onClick={handleNavItemClick}><i className='bx bx-user'></i> Free Sign Up</NavLink></li>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <li>
                                                                <div className="dropdown">
                                                                    <Link to="#" className="dropdown-toggle bx bx-user" data-bs-toggle="dropdown"><span className="sign-dropdown">Hello, {user_fname}</span></Link>
                                                                    <div className="dropdown-menu">
                                                                        {user_type != 4 && (
                                                                            <Link to="/qbhl-admin" className="dropdown-item" onClick={handleNavItemClick}>Your Profiles</Link>
                                                                        )}
                                                                        <Link to="/qbhl-admin/account" className="dropdown-item" onClick={handleNavItemClick}>Account Settings</Link>
                                                                        <Link to="/user/logout" className="dropdown-item" onClick={handleLogout}>Sign Out</Link>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </>
                                                    )}
                                                    <li><NavLink to="/trainers" onClick={handleNavItemClick}><i className='bx bx-user-circle'></i> Need a Trainer </NavLink></li>
                                                </ul>
                                            </div>
                                        </ul>
                                        <div className="search-box"><i className='bx bx-search'></i></div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
