import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import SearchBar from './SearchBar'
import Listing from './listing'
import { getProfiles } from '../../services/profiles/action'
import { getLabel } from '../../../common/getLabel'
import { loadMetaTags, PROFILE_PAGE_META_TYPE, SITE_TITLE, TOP_RANKING_PAGE_META_TYPE } from '../../common/common'
import { formatDate } from '../../../common/common'
const Profiles = ({ show_top_200 }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [currentPage, setCurrentPage] = useState(parseInt(queryParams.get('page'), 10) || 1);
    const pageName = show_top_200 ? 'qbhl-top-150' : 'qb-profiles';
    const limit = 25;
    const requestBody = {
        page: currentPage,
        grad_class: queryParams.get('grad_class') || "",
        style: queryParams.get('style') || "",
        state_code: queryParams.get('state_code') || "",
        limit: limit,
        top_200: show_top_200,
        display_trainer: false,
        keyword: queryParams.get('keyword') || "",
    };
    const search_string = queryParams.get('keyword') || "";
    const [filterdata, setFilterData] = useState(requestBody);
    const dispatch = useDispatch();
    const { profiles, totalCount } = useSelector((state) => state.profiles);
    const loading = useSelector((state) => state.profiles.loading);
    const error = useSelector((state) => state.profiles.error);
    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        updateURLWithFilters(updatedFilterData);
        dispatch(getProfiles(updatedFilterData));
    };

    const handleFilter = () => {
        const updatedFilterData = {
            ...filterdata,
            page: 1,
            top_200: show_top_200
        };
        setCurrentPage(1)
        updateURLWithFilters(updatedFilterData);
        dispatch(getProfiles(updatedFilterData));
    };

    const handleFilterUpdate = async (name, value) => {
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            [name]: value,
        };
        setFilterData(updatedFilterData);
    };

    const { headerFooter } = useSelector((state) => state.headerFooter);
    const site_title = headerFooter?.info?.CONF_SITE_TITLE || SITE_TITLE;
    const meta_title = show_top_200 ? 'Top 150 Quarterbacks By Class And Style' : 'Featuring The Top High School Quarterbacks In The U.S.';
    const metaTags = {
        meta_type: show_top_200 ? TOP_RANKING_PAGE_META_TYPE : PROFILE_PAGE_META_TYPE,
        meta_title: `${site_title} | ${meta_title}`,
        meta_record_id: 0,
        meta_advance_tag: show_top_200 ? 1 : 0
    }

    useEffect(() => {
        loadMetaTags(metaTags);
        dispatch(getProfiles(requestBody));
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            keyword: search_string ? decodeURIComponent(search_string.replace(/\+/g, ' ')) : ""
        };
        setFilterData(updatedFilterData);
    }, [show_top_200, search_string]);

    const buildQueryString = (data) => {
        const params = new URLSearchParams();
        const keys = ['grad_class', 'state_code', 'keyword', 'style', 'page'];

        keys.forEach(key => {
            if (data[key] !== undefined && data[key] !== '') {
                params.set(key, data[key]);
            }
        });

        return params.toString();
    };

    const updateURLWithFilters = (filterData) => {
        const queryString = buildQueryString(filterData);
        navigate(`/${pageName}?${queryString}`);
    };

    const handleClear = () => {
        const initialFilterData = {
            grad_class: "",
            style: "",
            state_code: "",
            keyword: "",
            page: 1,
            limit: 20,
            top_200: show_top_200,
            display_trainer: false
        };

        setCurrentPage(1);
        setFilterData(initialFilterData);
        const queryString = buildQueryString({ page: 1 });
        navigate(`/${pageName}?${queryString}`);
        dispatch(getProfiles(initialFilterData));
    };


    return (
        <>
            <div className="player-list">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="player-heading">
                                <h2>{show_top_200 ? getLabel("LBL_Top_Rankings") : getLabel("LBL_Player_Profile")}</h2>
                                {show_top_200 && (
                                    <p>{getLabel("LBL_Updated")} {formatDate(new Date())}</p>
                                )}
                            </div>
                        </div>

                        <SearchBar
                            handleFilterUpdate={handleFilterUpdate}
                            handleFilter={handleFilter}
                            filterdata={filterdata}
                            search_string={search_string}
                            handleClear={handleClear}
                        />

                        <Listing
                            error={error}
                            profiles={profiles}
                            loading={loading}
                            totalCount={totalCount}
                            currentPage={currentPage}
                            limit={limit}
                            handlePaginate={handlePaginate}
                            show_top_200={show_top_200}
                            filterdata={filterdata}
                        />

                    </div>
                </div>
            </div>
        </>
    )
}

export default Profiles
