import React from 'react'
import { Link } from "react-router-dom"
import Pagination from '../../../common/reactPaginate';
import { getLabel } from '../../../common/getLabel';
import { baseUrl } from '../../common/config';
import { getUserProfileStyles } from '../../../common/common';
import ProfileSkeletonFrontSideLoading from '../../common/skeleton-loading/profile-skeleton-loading-frontend';
const ArchiveProfileListing = (props) => {
    const { error, archives, loading, totalCount, handlePaginate, limit } = props;
    const handlePageClick = (selectedPage) => {
        handlePaginate(selectedPage);
    };
    const totalPages = Math.ceil(totalCount / limit);
    return (
        <>
            <div className="row news-detail-space">
                {loading ? (
                    <ProfileSkeletonFrontSideLoading length={limit} />
                ) : error ? (
                    <h3 style={{ textAlign: "center" }}>
                        {getLabel("LBL_Some_thing_wrong_Please_try_again_later")}
                    </h3>
                ) : (
                    <>
                        {archives && archives.length > 0 ? (
                            archives.map((profile, index) => {
                                const rank_profile_data = JSON.parse(profile.rank_profile_data);
                                const school = (rank_profile_data.school) ? JSON.parse(rank_profile_data.school) : '';
                                const height_feet = (rank_profile_data.height_feet) ? JSON.parse(rank_profile_data.height_feet) : '';
                                const height_inch = (rank_profile_data.height_inch) ? JSON.parse(rank_profile_data.height_inch) : '';
                                const weight = (rank_profile_data.weight) ? JSON.parse(rank_profile_data.weight) : '';
                                const hs_name = (school && school.hs_name) ? school.hs_name : '';
                                const hs_state = (school && school.hs_state) ? school.hs_state : '';
                                let stats = '';
                                if (height_feet !== '' && height_inch !== '') {
                                    stats = `${height_feet}'${height_inch}"`;
                                }
                                if (weight) {
                                    stats += `  ${weight} lbs.`;
                                }
                                let starsRating = 0;
                                if (rank_profile_data.urank_profile_data_style === 3 || rank_profile_data.urank_profile_data_style === 4) {
                                    starsRating = (rank_profile_data.wr_te_stars) ? (rank_profile_data.wr_te_stars * 100) / 5 : 0;
                                } else {
                                    starsRating = (rank_profile_data.stars) ? (rank_profile_data.stars * 100) / 5 : 0;
                                }

                                return (
                                    <div className="col-lg-12 col-sm-6" key={index}>
                                        <div className="qbh-player-list-box">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="player-profile-pics">
                                                        <figure>
                                                            <Link to={`/profile/${rank_profile_data.uprofile_slug}`}>
                                                                <img
                                                                    src={profile.images && profile.images[0] && profile.images[0].afile_physical_path ? `${baseUrl}/${profile.images[0].afile_physical_path}` : "/images//qbh-hit.jpg"}
                                                                    className="img-fluid"
                                                                    alt={profile.uprofile_fname}
                                                                />
                                                            </Link>
                                                        </figure>
                                                        <div className="player-pro-name"><Link to={`/profile/${rank_profile_data.uprofile_slug}`}><h5>{rank_profile_data.uprofile_fname} {rank_profile_data.uprofile_lname}</h5></Link></div>
                                                    </div>
                                                    <div className="line-player"></div>
                                                </div>

                                                <div className="col col-lg-4 col-sm-4 box-padding-left">
                                                    <div className="school-list">
                                                        <ul className="border-dashd-right">
                                                            <li className="bold">{hs_name}</li>
                                                            <li className="bold">{getLabel('LBL_State:')} {hs_state} </li>
                                                            <li className="bold">{getLabel('LBL_Class:')} {rank_profile_data.uprofile_grad_class}</li>
                                                            <li className="bold">{getLabel('LBL_Style:')} {getUserProfileStyles[rank_profile_data.uprofile_style]} </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className="col col-lg-4 col-sm-4 box-padding-right">
                                                    <div className="school-list">
                                                        <ul>
                                                            <li>{getLabel('LBL_Height/Weight:')} {stats} </li>
                                                            <li>{getLabel('LBL_Score:')} {rank_profile_data.uprofile_eval_final}</li>
                                                            {starsRating ? (
                                                                <li>
                                                                    <div className="review__rating star-rating-menu">
                                                                        <div className="icn" style={{ width: starsRating + '%' }}></div>
                                                                    </div>
                                                                </li>
                                                            ) : (
                                                                <>
                                                                    {getLabel('LBL_Not_Yet_Rated')}
                                                                </>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <h4 className="my-4" style={{ textAlign: "center" }}>
                                {getLabel("LBL_No_result_found")}
                            </h4>
                        )}
                    </>
                )}
                {archives && archives.length > 0 && (
                    <>
                        <div className="col-lg-12">
                            <div className="custom-pagination">
                                <nav aria-label="...">
                                    <Pagination totalPages={totalPages} handlePageClick={handlePageClick} />
                                </nav>

                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default ArchiveProfileListing
