import React from 'react'
import { Link } from "react-router-dom"
import Pagination from '../../../common/reactPaginate';
import { getLabel } from '../../../common/getLabel';
import { baseUrl } from '../../common/config';
import TrainerSkeletonFrontSideLoading from '../../common/skeleton-loading/trainer-skeleton-loading';
const Listing = (props) => {
    const { error, profiles, loading, totalCount, handlePaginate, limit } = props;
    const handlePageClick = (selectedPage) => {
        handlePaginate(selectedPage);
    };
    const totalPages = Math.ceil(totalCount / limit);
    return (
        <>
            <div className="row news-detail-space">
                {loading ? (
                    <TrainerSkeletonFrontSideLoading length={limit} />
                ) : error ? (
                    <h3 style={{ textAlign: "center" }}>
                        {getLabel("LBL_Some_thing_wrong_Please_try_again_later")}
                    </h3>
                ) : (
                    <>
                        {profiles && profiles.length > 0 ? (
                            profiles.map((profile, index) => (
                                <div className="col-lg-12 col-sm-6" key={index}>
                                    <div className="qbh-player-list-box">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="trainer-pro">
                                                    <figure>
                                                        <Link to={`/trainer-profile/${profile["profileUrl.urlrewrite_custom"]}`}>
                                                            <img
                                                                src={profile.images && profile.images[0] && profile.images[0].afile_physical_path ? `${baseUrl}/${profile.images[0].afile_physical_path}` : "/images//qbh-hit.jpg"}
                                                                className="img-fluid"
                                                                alt={profile.uprofile_fname}
                                                            />
                                                        </Link>
                                                    </figure>
                                                </div>
                                            </div>

                                            <div className="col-lg-9">
                                                <div className="school-list-trainer">
                                                    <div className="player-pro-name"><Link to={`/trainer-profile/${profile["profileUrl.urlrewrite_custom"]}`}><h5>{profile.uprofile_company_name}</h5></Link></div>
                                                    <div className="address-list contact-info">
                                                        <ul>
                                                            {profile.uprofile_address && (
                                                                <li><i className="bx bx-map" style={{ color: "#ab6601" }}></i> {profile.uprofile_address}, {profile.uprofile_city} {profile.uprofile_state_code} {profile.uprofile_zip}</li>
                                                            )}
                                                            {profile.uprofile_phone_no && (
                                                                <li><i className="bx bx-phone-call" style={{ color: "#ab6601" }}></i><a href={`tel:${profile.uprofile_phone_no}`}>{profile.uprofile_phone_no}  </a></li>
                                                            )}
                                                            {profile.uprofile_email && (
                                                                <li><i className="bx bx-envelope-open" style={{ color: "#ab6601" }}></i><a href={`mailto:${profile.uprofile_email}`}>{profile.uprofile_email} </a></li>
                                                            )}
                                                            {profile.uprofile_website && (
                                                                <li><i className='bx bx-globe' style={{ color: "#ab6601" }} ></i> {profile.uprofile_website}</li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <h4 className="my-4" style={{ textAlign: "center" }}>
                                {getLabel("LBL_No_result_found")}
                            </h4>
                        )}
                    </>
                )}
                {profiles && profiles.length > 0 && (
                    <>
                        <div className="col-lg-12">
                            <div className="custom-pagination">
                                <nav aria-label="...">
                                    <Pagination totalPages={totalPages} handlePageClick={handlePageClick} />
                                </nav>

                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default Listing
