import React from 'react'
import { Link } from "react-router-dom"
import Pagination from '../../../common/reactPaginate';
import { getLabel } from '../../../common/getLabel';
import { baseUrl } from '../../common/config';
import { formatOfferDate, getUserProfileStyles } from '../../../common/common';
import ProfileSkeletonFrontSideLoading from '../../common/skeleton-loading/profile-skeleton-loading-frontend';
const Listing = (props) => {
    const { error, premiumOffers, loading, totalCount, handlePaginate, limit } = props;
    const handlePageClick = (selectedPage) => {
        handlePaginate(selectedPage);
    };
    const totalPages = Math.ceil(totalCount / limit);
    return (
        <>
            <div className="row news-detail-space">
                {loading ? (
                    <ProfileSkeletonFrontSideLoading length={limit} />
                ) : error ? (
                    <h3 style={{ textAlign: "center" }}>
                        {getLabel("LBL_Some_thing_wrong_Please_try_again_later")}
                    </h3>
                ) : (
                    <>
                        {premiumOffers && premiumOffers.length > 0 ? (
                            premiumOffers.map((profile, index) => {
                                return (
                                    <div className="col-lg-12 col-sm-6" key={index}>
                                        <div className="qbh-player-list-box">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="player-profile-pics">
                                                        <figure><Link to={`/profile/${profile["poffersUrl.urlrewrite_custom"]}`}>
                                                            <img
                                                                src={profile.images && profile.images[0] && profile.images[0].afile_physical_path ? `${baseUrl}/${profile.images[0].afile_physical_path}` : "/images//qbh-hit.jpg"}
                                                                className="img-fluid"
                                                                alt={profile["poffers.uprofile_fname"]}
                                                            />
                                                        </Link></figure>
                                                        <div className="player-pro-name"><Link to={`/profile/${profile["poffersUrl.urlrewrite_custom"]}`}><h5>{profile["poffers.uprofile_fname"]} {profile["poffers.uprofile_lname"]}</h5></Link></div>
                                                    </div>
                                                    <div className="line-player"></div>
                                                </div>

                                                <div className="col col-lg-4 col-sm-4 box-padding-left">
                                                    <div className="school-list">
                                                        <ul className="border-dashd-right">
                                                            <li className="bold">{getLabel('LBL_Offer')}: {formatOfferDate(profile.poffer_date)}</li>
                                                            <li className="bold">{profile.poffer_college}</li>
                                                            <li className="bold"><span>{getLabel('LBL_Class')}:</span> {profile["poffers.uprofile_grad_class"]}</li>
                                                            <li className="bold"><span>{getLabel('LBL_Style')}: </span> {getUserProfileStyles[profile["poffers.uprofile_style"]]} </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                {profile.school_image && profile.school_image[0] && (
                                                    <div className="col col-lg-4 col-sm-4 box-padding-right">
                                                        <div className="school-list">
                                                            <figure><img src={`${baseUrl}/${profile.school_image[0].afile_physical_path}`} className="img-fluid" /></figure>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <h4 className="my-4" style={{ textAlign: "center" }}>
                                {getLabel("LBL_No_result_found")}
                            </h4>
                        )}
                    </>
                )}
                {premiumOffers && premiumOffers.length > 0 && (
                    <>
                        <div className="col-lg-12">
                            <div className="custom-pagination">
                                <nav aria-label="...">
                                    <Pagination totalPages={totalPages} handlePageClick={handlePageClick} />
                                </nav>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default Listing
