import React from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { baseUrl } from '../../common/config';
import { getUserProfileStyles } from '../../../common/common';
import { getLabel } from '../../../common/getLabel';

const PremiumProfiles = ({ sliderSetting, premiumProfiles }) => {
    const updatedSliderSetting = {
        ...sliderSetting,
        arrows: premiumProfiles && premiumProfiles.length > 4 ? true : false
    }
    return (
        <>
            <div className="container-fluid custom-width top-ranking">
                <div className="row justify-content-center">
                    <div className="col-lg-12 qbh-padding-left">
                        <div className="heading">
                            <h2>FEATURED <span className="account-color">PREMIUM PROFILES</span></h2>
                            <div className="space-line"></div>
                        </div>
                    </div>

                    <Slider className="row popular-product-slider" {...updatedSliderSetting}>
                        {premiumProfiles && premiumProfiles.map((profile, index) => {
                            const school = (profile.school) ? JSON.parse(profile.school) : '';
                            const hs_name = (school && school.hs_name) ? school.hs_name : '';
                            const hs_city = (school && school.hs_city) ? school.hs_city : '';
                            const hs_state = (school && school.hs_state) ? school.hs_state : '';
                            let starsRating = '';
                            if (profile.uprofile_style === 3 || profile.uprofile_style === 4) {
                                starsRating = (profile.wr_te_stars) ? (profile.wr_te_stars * 100) / 5 : '';
                            } else {
                                starsRating = (profile.stars) ? (profile.stars * 100) / 5 : '';
                            }
                            return (
                                <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3 col-xxl-3 popular-product-slide" key={index}>
                                    <div className="qbh-box-main">
                                        <div className="qbh-image-box profile--slider">
                                            <figure><Link to={`/profile/${profile["profileUrl.urlrewrite_custom"]}`}>
                                                <img
                                                    src={profile.images && profile.images[0] && profile.images[0].afile_physical_path ? `${baseUrl}/${profile.images[0].afile_physical_path}` : "/images//qbh-hit.jpg"}
                                                    className="img-fluid"
                                                    alt={profile.uprofile_fname}
                                                />
                                            </Link></figure>
                                        </div>
                                        <div className="pro-style">
                                            <div className="qbh-name-sports">
                                                <div className="name"><span className="pro">{getUserProfileStyles[profile.uprofile_style]}</span></div>
                                            </div>
                                        </div>
                                        <div className="left-space">
                                            <div className="sports-name"><Link to={`/profile/${profile["profileUrl.urlrewrite_custom"]}`}>{profile.uprofile_fname} {profile.uprofile_lname}</Link></div>
                                            <div className="class-sport">Class of {profile.uprofile_grad_class}</div>
                                            {starsRating ? (
                                                <div className="review__rating star-rating-menu">
                                                    <div className="icn" style={{ width: starsRating + '%' }}></div>
                                                </div>
                                            ) : (
                                                <>
                                                    {getLabel('LBL_Not_Yet_Rated')}
                                                </>
                                            )}
                                            <div className="high-school">{hs_name}</div>
                                            <div className="player-id">{hs_city}, {hs_state}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                    <div className="view-all-btn"><Link to="/qbhl-premium-profiles" className="btn btn-primary ">{getLabel('LBL_View_All')}</Link></div>
                </div>
            </div>
        </>
    )
}

export default PremiumProfiles
