import React, { useEffect, useState, useRef } from 'react'
import { useFormik } from "formik";
import { deleteProfileImage, setupProfileData, uploadProfileImage, uploadProfileCoverImage } from '../../../services/account/action';
import { useNavigate } from "react-router-dom";
import { trainerProfileSchema } from '../../../common/validation';
import { getLabel } from '../../../../common/getLabel'
import { ImageDeleteModal } from '../../../common/imageDeleteModal';
import ProfileImageModal from '../profileImageModal';
import { baseUrl } from '../../../common/config';
import { scrollToFirstError } from '../../../../common/common';
import { InputMask } from '@react-input/mask';

const TrainerInformation = (props) => {
    const { profileData, profileId, stateListing } = props;
    const [profileImage, setProfileImage] = useState((profileData && profileData.image) ? profileData.image : []);
    const [profileCoverImage, setProfileCoverImage] = useState((profileData && profileData.cover_image) ? profileData.cover_image : []);
    const [imageDeleteModalshow, setImageDeleteModalShow] = useState(false);
    const [profileImageModalShow, setProfileImageModalShow] = useState(false);
    const [imageDeleteType, setImageDeleteType] = useState('');
    const [imageUploadType, setImageUploadType] = useState('');
    const [imageData, setImageData] = useState(null);

    const social_media = (profileData && profileData.social_media) ? JSON.parse(profileData.social_media) : '';
    const video_info = (profileData && profileData.video) ? JSON.parse(profileData.video) : '';
    const about = (profileData && profileData.about) ? JSON.parse(profileData.about) : '';
    const trainer_services_info = (profileData && profileData.trainer_services) ? JSON.parse(profileData.trainer_services) : '';
    const client = (profileData && profileData.client) ? JSON.parse(profileData.client) : '';
    // const trainer_testimonials_info = (profileData && profileData.trainer_testimonials) ? JSON.parse(profileData.trainer_testimonials) : '';

    const initialValues = {
        profile_id: (profileData) ? profileData.uprofile_id : '',
        first_name: (profileData) ? profileData.uprofile_fname : '',
        last_name: (profileData) ? profileData.uprofile_lname : '',
        phone_no: (profileData) ? profileData.uprofile_phone_no : '',
        website: (profileData) ? profileData.uprofile_website : '',
        company_name: (profileData) ? profileData.uprofile_company_name : '',
        style: (profileData) ? profileData.uprofile_style : '',
        email: (profileData) ? profileData.uprofile_email : '',
        address: (profileData) ? profileData.uprofile_address : '',
        city: (profileData) ? profileData.uprofile_city : '',
        state_code: (profileData) ? profileData.uprofile_state_code : '',
        zip: (profileData) ? profileData.uprofile_zip : '',
        social_media_facebook: (social_media && social_media.facebook) ? social_media.facebook : '',
        social_media_instagram: (social_media && social_media.instagram) ? social_media.instagram : '',
        social_media_twitter: (social_media && social_media.twitter) ? social_media.twitter : '',
        social_media_snapchat: (social_media && social_media.snapchat) ? social_media.snapchat : '',
        video: (video_info && video_info.url) ? video_info.url : '',
        about: about,
        client: client,
        trainer_services: trainer_services_info,
        // trainer_testimonials: trainer_testimonials_info,
    };

    const navigate = useNavigate("")

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: trainerProfileSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            const postData = {
                profile_id: values.profile_id,
                // user_id: user_id,
                first_name: values.first_name,
                last_name: values.last_name,
                company_name: values.company_name,
                email: values.email,
                phone_no: values.phone_no,
                website: values.website,
                style: "0",
                address: values.address,
                city: values.city,
                state_code: values.state_code,
                zip: values.zip,
                profile_info: {
                    social_media: {
                        facebook: values.social_media_facebook,
                        snapchat: values.social_media_snapchat,
                        instagram: values.social_media_instagram,
                        twitter: values.social_media_twitter,
                    },
                    // trainer_URL: values.trainer_URL,
                    about: values.about,
                    video: { "url": values.video },
                    trainer_services: initialValues.trainer_services,
                    // trainer_testimonials: initialValues.trainer_testimonials,
                    client: initialValues.client,
                }
            }
            try {
                const response = await setupProfileData(postData);
                if (response.success === true) {
                    navigate('/qbhl-admin');
                }
            } catch (error) {
                console.log(error);
            }
        },
    });
    const handleChange = (e) => {
        formik.handleChange(e);
    };

    const handleImageDeleteModalShow = (imageType) => {
        setImageDeleteType(imageType);
        setImageDeleteModalShow(true);
    };

    const handleProfileImageModalShow = (imageType, imageData) => {
        setImageUploadType(imageType);
        setImageData(imageData);
        setProfileImageModalShow(true);
    };

    const handleDeleteImage = async () => {
        try {
            const data = { profile_id: profileId, image_type: imageDeleteType };
            await deleteProfileImage(data);
            handleImageDeleteModalClose();
        } catch (error) { }
    };

    const handleImageDeleteModalClose = () => setImageDeleteModalShow(false);
    const handleProfileImageModalClose = () => setProfileImageModalShow(false);

    const handleImageChange = async (file) => {
        try {
            const formData = new FormData();
            formData.append("profile_id", profileId);
            if (imageUploadType == 1) {
                formData.append("profile_image", file);
                const response = await uploadProfileImage(formData);
                if (response.success) {
                    setProfileImage(response.data);
                }
            } else {
                formData.append("profile_image", file);
                const response = await uploadProfileCoverImage(formData);
                if (response.success) {
                    setProfileCoverImage(response.data);
                }
            }
            handleProfileImageModalClose();
        } catch (error) {
            console.log("error", error);
        }
    };

    const fieldRefs = useRef({});

    useEffect(() => {
        scrollToFirstError(formik, fieldRefs);
    }, [formik.errors, formik.touched, formik.isSubmitting]);

    return (
        <>
            <h4>{getLabel('LBL_Trainer_Information')}</h4>
            <div className="bg-white-tranier mb-5">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="from-group">
                                <label>{getLabel('LBL_*First_Name')}</label>
                                <input type="text" name="first_name" value={formik.values.first_name} ref={el => fieldRefs.current.first_name = el} onKeyDown={(e) => /[a-zA-Z ]/.test(e.key) || e.preventDefault()} onChange={handleChange} className="form-control" placeholder={getLabel('LBL_*First_Name')} aria-label="First name" />
                            </div>
                            {formik.touched.first_name && formik.errors.first_name && (
                                <span style={{ color: '#b94a48' }}>{formik.errors.first_name}</span>
                            )}
                        </div>


                        <div className="col-lg-6">
                            <div className="from-group">
                                <label>{getLabel('LBL_*Last_Name')}</label>
                                <input type="text" name="last_name" value={formik.values.last_name} ref={el => fieldRefs.current.last_name = el} onKeyDown={(e) => /[a-zA-Z ]/.test(e.key) || e.preventDefault()} onChange={handleChange} className="form-control" placeholder={getLabel('LBL_*Last_Name')} aria-label="Last ame" />
                            </div>
                            {formik.touched.last_name && formik.errors.last_name && (
                                <span style={{ color: '#b94a48' }}>{formik.errors.last_name}</span>
                            )}
                        </div>

                        <div className="col-lg-6">
                            <div className="from-group">
                                <label>{getLabel('LBL_*Business_Name')}</label>
                                <input type="text" className="form-control" name="company_name" ref={el => fieldRefs.current.company_name = el} value={formik.values.company_name} onChange={handleChange} placeholder={getLabel('LBL_*Business_Name')} aria-label="Business Name" />
                            </div>
                            {formik.touched.company_name && formik.errors.company_name && (
                                <span style={{ color: '#b94a48' }}>{formik.errors.company_name}</span>
                            )}
                        </div>

                        <div className="col-lg-6">
                            <div className="from-group">
                                <label>{getLabel('LBL_*Address')}</label>
                                <input type="text" className="form-control" name="address" value={formik.values.address} ref={el => fieldRefs.current.address = el} onChange={handleChange} placeholder={getLabel('LBL_*Address')} aria-label="Address" />
                            </div>
                            {formik.touched.address && formik.errors.address && (
                                <span style={{ color: '#b94a48' }}>{formik.errors.address}</span>
                            )}
                        </div>

                        <div className="col-lg-4">
                            <div className="from-group">
                                <label>{getLabel('LBL_*State')}</label>
                                <select as="select" className="form-select" name="state_code" value={formik.values.state_code} ref={el => fieldRefs.current.state_code = el} aria-label="Default select example" onChange={handleChange}>
                                    <option value="">{getLabel('LBL_*State')}</option>
                                    {stateListing && stateListing().map((state, index) => (
                                        <option key={index} value={state}>{state}</option>
                                    ))}
                                </select>
                            </div>
                            {formik.touched.state_code && formik.errors.state_code && (
                                <span style={{ color: '#b94a48' }}>{formik.errors.state_code}</span>
                            )}
                        </div>


                        <div className="col-lg-4">
                            <div className="from-group">
                                <label>{getLabel('LBL_*City')}</label>
                                <input type="text" className="form-control" name="city" value={formik.values.city} ref={el => fieldRefs.current.city = el} onChange={handleChange} placeholder={getLabel('LBL_*City')} aria-label="City" />
                            </div>
                            {formik.touched.city && formik.errors.city && (
                                <span style={{ color: '#b94a48' }}>{formik.errors.city}</span>
                            )}
                        </div>


                        <div className="col-lg-4">
                            <div className="from-group">
                                <label>{getLabel('LBL_*Postal_Code')} </label>
                                <input type="text" className="form-control numberonly" name="zip" value={formik.values.zip} ref={el => fieldRefs.current.zip = el} onChange={handleChange} placeholder={getLabel('LBL_*Postal_Code')} aria-label="Address" />
                            </div>
                            {formik.touched.zip && formik.errors.zip && (
                                <span style={{ color: '#b94a48' }}>{formik.errors.zip}</span>
                            )}
                        </div>


                        <div className="col-lg-12">
                            <div className="from-group">
                                <label>{getLabel('LBL_Website_URL')} </label>
                                <input type="text" className="form-control" name="website" value={formik.values.website} onChange={handleChange} placeholder={getLabel('LBL_Website_URL')} aria-label="Website URL" />
                            </div>
                        </div>


                        <div className="col-lg-6">
                            <div className="from-group">
                                <label>{getLabel('LBL_*Email_Address')} </label>
                                <input type="Email Address" className="form-control" name="email" value={formik.values.email} ref={el => fieldRefs.current.email = el} onChange={handleChange} placeholder={getLabel('LBL_*Email_Address')} aria-label="Email Address" />
                            </div>
                            {formik.touched.email && formik.errors.email && (
                                <span style={{ color: '#b94a48' }}>{formik.errors.email}</span>
                            )}
                        </div>



                        <div className="col-lg-6">
                            <div className="from-group">
                                <label>{getLabel('LBL_*Phone')} </label>
                                <InputMask type="text" className="form-control numberonly" mask="(___) ___-____" replacement={{ _: /\d/ }} name="phone_no" value={formik.values.phone_no} placeholder={getLabel('LBL_Phone')} aria-label="Phone" onChange={handleChange} />
                            </div>
                            {formik.touched.phone_no && formik.errors.phone_no && (
                                <span style={{ color: '#b94a48' }}>{formik.errors.phone_no}</span>
                            )}
                        </div>


                        <div className="col-lg-12 my-4">
                            {profileId ? (
                                <div className="cover-photo">
                                    {profileCoverImage && profileCoverImage.length > 0 && (
                                        <div className="cover-close-proflie" onClick={() => handleImageDeleteModalShow(2)}><i className="bx bx-x"></i></div>
                                    )}
                                    <div className="cover-photo-image">
                                        <figure>
                                            {profileCoverImage && profileCoverImage.length > 0 ? (
                                                <img src={`${baseUrl}/${profileCoverImage[0].afile_physical_path}`} alt="Profile" className="img-fluid" />
                                            ) : (
                                                <img src="/images/profile-detail-image.jpg" alt="Profile" className="img-fluid" />
                                            )}
                                        </figure>
                                        <div className="change-image" onClick={() => handleProfileImageModalShow(2, profileCoverImage)}>
                                            <i className='bx bx-camera'></i>
                                            {getLabel('LBL_Cover_Photo')}
                                        </div>
                                    </div>
                                    <div className="profile-picture">
                                        <div className="proile-pic-user">
                                            {profileImage && profileImage.length > 0 ? (
                                                <img src={`${baseUrl}/${profileImage[0].afile_physical_path}`} alt="Profile" className="img-fluid" />
                                            ) : (
                                                <img src="/images/qbh-hit.jpg" alt="Profile" className="img-fluid" />
                                            )}
                                        </div>
                                        {profileImage && profileImage.length > 0 && (
                                            <div className="profile-close-proflie" onClick={() => handleImageDeleteModalShow(1)}><i className="bx bx-x"></i></div>
                                        )}
                                        <div className="profile-icon" onClick={() => handleProfileImageModalShow(1, profileImage)}>
                                            <i className='bx bx-camera'></i>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <p className="save-profile">{getLabel('LBL_Once_you_save_your_profile_you_may_add_images.')}</p>
                            )}
                        </div>



                        <div className="col-lg-12 mt-5">
                            <div className="bio">
                                <label>{getLabel('LBL_About_/_Bio_(300_max_characters)')}</label>
                                <textarea rows="4" cols="100" maxLength="300" name="about" value={formik.values.about} onChange={handleChange}></textarea>
                            </div>
                        </div>


                        <div className="col-lg-12 mt-4">
                            <div className="bio">
                                <label>{getLabel('LBL_Social_Accounts')}</label>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="bio">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" name="social_media_twitter" value={formik.values.social_media_twitter} onChange={handleChange} placeholder={getLabel('LBL_Twitter')} aria-label="Twitter username" aria-describedby="twitter" />
                                    <span className="input-group-text basic-addon2" id="twitter"><i className='bx bxl-twitter'></i></span>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="bio">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" name="social_media_snapchat" value={formik.values.social_media_snapchat} onChange={handleChange} placeholder={getLabel('LBL_Snapchat')} aria-label="Snapchat Profile" aria-describedby="snapchat" />
                                    <span className="input-group-text basic-addon2" id="snapchat"><i className='bx bxl-snapchat'></i></span>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-6">
                            <div className="bio">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" name="social_media_instagram" value={formik.values.social_media_instagram} onChange={handleChange} placeholder={getLabel('LBL_Instagram')} aria-label="Instagram Profile" aria-describedby="instagram" />
                                    <span className="input-group-text basic-addon2" id="instagram"><i className='bx bxl-instagram'></i></span>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-6">
                            <div className="bio">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" name="social_media_facebook" value={formik.values.social_media_facebook} onChange={handleChange} placeholder={getLabel('LBL_Facebook')} aria-label="Facebook Profile" aria-describedby="facebook" />
                                    <span className="input-group-text basic-addon2" id="facebook"><i className='bx bxl-facebook'></i></span>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 mt-4">
                            <div className="bio">
                                <label>{getLabel('LBL_Video_Embed')}</label>
                            </div>
                            <p>{getLabel('LBL_Enter_complete_embed_code_from_Hudl_or_YouTube.')}</p>
                            <p><a href="https://www.hudl.com/support/v3/an-athletes-guide-to-hudl/highlights/embed-your-highlight-reel" target="_blank" rel="noreferrer">{getLabel('LBL_How_to_get_embed_for_Hudl')}</a></p>
                            <p><a href="https://support.google.com/youtube/answer/171780?hl=en" target="_blank" rel="noreferrer">{getLabel('LBL_How_to_get_embed_for_YouTube')}</a></p>
                        </div>


                        <div className="col-lg-12 mt-5">
                            <div className="bio">
                                <textarea rows="4" cols="100" name="video" value={formik.values.video} onChange={handleChange} placeholder={getLabel('LBL_Video_Embed_Code')}></textarea>
                                <small>{getLabel('LBL_Note')}:- {getLabel('LBL_Please_enter_complete_iframe')}</small>
                            </div>
                        </div>


                        <div className="col-lg-12">
                            <div className="from-group">
                                <button type="submit" className="btn btn-default btn-sm btn-save btn-umber">{getLabel('LBL_Save_Changes')}</button>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
            {profileId && (
                <>
                    <ProfileImageModal imageData={imageData} profileImageModalShow={profileImageModalShow} handleProfileImageModalClose={handleProfileImageModalClose} handleImageChange={handleImageChange} />
                    <ImageDeleteModal
                        imageDeleteModalshow={imageDeleteModalshow}
                        handleImageDeleteModalClose={handleImageDeleteModalClose}
                        handleDeleteImage={handleDeleteImage}
                    />
                </>
            )}
        </>
    )
}

export default TrainerInformation
